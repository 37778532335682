<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="80%" @click:outside="clickOutside">
      <template v-slot:activator="{ on, attrs }">
        <p id="movementDetail" v-bind="attrs" v-on="on" @click="detail"></p>
      </template>
      <v-container
        style="background: white; border-radius: 5px; position: relative;"
        v-if="result !== null"
      >
        <v-card
          flat
          style="
                z-index: 2;
                position: sticky;
                top: 0;
                background: white;
                border-bottom: 1px solid black;
                margin-bottom: 10px;
                display: flex;
                justify-content: space-between;
              "
        >
          <div
            style="
            display: flex;
            jsutify-content: center;
            align-items: center;
            margin-left: 10px;
          "
          >
            <v-toolbar-title class="overline"
              ><p style="font-size: 20px; margin: 0; font-weight: bold">
                Detail Penerimaan Aset
              </p>
            </v-toolbar-title>
          </div>
          <div>
            <v-btn tile color="green" class="ma-2" style="color: white">
              Proses
            </v-btn>
            <v-btn tile color="orange" class="ma-2" style="color: white">
              discard
            </v-btn>
            <v-btn tile color="red" class="ma-2" style="color: white">
              Cancel
            </v-btn>
            <v-btn
              @click="close"
              tile
              color="black"
              class="ma-2"
              style="color: white"
            >
              Tutup
            </v-btn>
          </div>
        </v-card>

        <v-row style="padding: 15px; margin-top:10px;">
          <v-col md="3" style="padding: 0">
            <div style="padding: 0 10px">
              <v-col cols="12" style="padding: 0">
                <p
                  style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
                >
                  Tgl. Terima
                </p>
              </v-col>
              <v-col cols="12" style="padding: 0">
                <v-text-field dense readonly style="margin: 0" />
              </v-col>
            </div>
          </v-col>
          <v-col md="9" style="padding: 0"> </v-col>
        </v-row>
        <v-row style="padding: 15px; margin-top:10px;">
          <v-col md="3" style="padding: 0">
            <div style="padding: 0 10px">
              <v-col cols="12" style="padding: 0">
                <p
                  style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
                >
                  Penerima
                </p>
              </v-col>
              <v-col cols="12" style="padding: 0">
                <v-text-field dense outlined style="margin: 0" />
              </v-col>
            </div>
          </v-col>
          <v-col md="3" style="padding: 0">
            <div style="padding: 0 10px">
              <v-col cols="12" style="padding: 0">
                <p
                  style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
                >
                  Plant
                </p>
              </v-col>
              <v-col cols="12" style="padding: 0">
                <v-text-field dense outlined style="margin: 0" />
              </v-col>
            </div>
          </v-col>
          <v-col md="3" style="padding: 0">
            <div style="padding: 0 10px">
              <v-col cols="12" style="padding: 0">
                <p
                  style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
                >
                  Department
                </p>
              </v-col>
              <v-col cols="12" style="padding: 0">
                <v-text-field dense outlined style="margin: 0" />
              </v-col>
            </div>
          </v-col>
          <v-col md="3" style="padding: 0">
            <div style="padding: 0 10px">
              <v-col cols="12" style="padding: 0">
                <p
                  style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
                >
                  Email
                </p>
              </v-col>
              <v-col cols="12" style="padding: 0">
                <v-text-field dense outlined style="margin: 0" />
              </v-col>
            </div>
          </v-col>
        </v-row>
        <hr />
        <div>
          <div
            style="
            display: flex;
            jsutify-content: center;
            align-items: center;
            margin-left: 10px;
          "
          >
            <v-toolbar-title class="overline"
              ><p style="font-size: 16px; margin: 0; font-weight: bold">
                Data Pemindahan
              </p>
            </v-toolbar-title>
          </div>
          <v-row style="padding: 15px; margin-top:10px;">
            <v-col md="3" style="padding: 0">
              <div style="padding: 0 10px">
                <v-col cols="12" style="padding: 0">
                  <p
                    style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
                  >
                    No. Pemindahan
                  </p>
                </v-col>
                <v-col cols="12" style="padding: 0">
                  <v-text-field dense readonly style="margin: 0" />
                </v-col>
              </div>
            </v-col>
            <v-col md="3" style="padding: 0">
              <div style="padding: 0 10px">
                <v-col cols="12" style="padding: 0">
                  <p
                    style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
                  >
                    Tgl. Pemindahan
                  </p>
                </v-col>
                <v-col cols="12" style="padding: 0">
                  <v-text-field dense readonly style="margin: 0" />
                </v-col>
              </div>
            </v-col>
            <v-col md="3" style="padding: 0">
              <div style="padding: 0 10px">
                <v-col cols="12" style="padding: 0">
                  <p
                    style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
                  >
                    Destinasi
                  </p>
                </v-col>
                <v-col cols="12" style="padding: 0">
                  <v-text-field dense readonly style="margin: 0" />
                </v-col>
              </div>
            </v-col>
            <v-col md="3" style="padding: 0">
              <div style="padding: 0 10px">
                <v-col cols="12" style="padding: 0">
                  <p
                    style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
                  >
                    Total Item
                  </p>
                </v-col>
                <v-col cols="12" style="padding: 0">
                  <v-text-field dense readonly style="margin: 0" />
                </v-col>
              </div>
            </v-col>
          </v-row>
          <v-row style="padding: 15px; margin-top:10px;">
            <v-col md="3" style="padding: 0">
              <div style="padding: 0 10px">
                <v-col cols="12" style="padding: 0">
                  <p
                    style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
                  >
                    PIC
                  </p>
                </v-col>
                <v-col cols="12" style="padding: 0">
                  <v-text-field dense readonly style="margin: 0" />
                </v-col>
              </div>
            </v-col>
            <v-col md="3" style="padding: 0">
              <div style="padding: 0 10px">
                <v-col cols="12" style="padding: 0">
                  <p
                    style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
                  >
                    Plant
                  </p>
                </v-col>
                <v-col cols="12" style="padding: 0">
                  <v-text-field dense readonly style="margin: 0" />
                </v-col>
              </div>
            </v-col>
            <v-col md="3" style="padding: 0">
              <div style="padding: 0 10px">
                <v-col cols="12" style="padding: 0">
                  <p
                    style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
                  >
                    Department
                  </p>
                </v-col>
                <v-col cols="12" style="padding: 0">
                  <v-text-field dense readonly style="margin: 0" />
                </v-col>
              </div>
            </v-col>
            <v-col md="3" style="padding: 0">
              <div style="padding: 0 10px">
                <v-col cols="12" style="padding: 0">
                  <p
                    style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
                  >
                    Email
                  </p>
                </v-col>
                <v-col cols="12" style="padding: 0">
                  <v-text-field dense readonly style="margin: 0" />
                </v-col>
              </div>
            </v-col>
          </v-row>

          <v-row style="padding: 15px">
            <v-col md="12" style="padding: 0 10px">
              <v-card
                flat
                style="
            top: 0;
            background: white;
            margin-top: 20px;
            margin-bottom: 40px;"
              >
                <v-data-table
                  :headers="headers"
                  class="elevation-1 mx-auto"
                  style="width:100%; margin-bottom:10px; border:1px solid #e0e0e0"
                  :items-per-page="10"
                  hide-default-footer
                >
                </v-data-table>
              </v-card>
            </v-col>
          </v-row>

          <v-row style="padding: 15px">
            <v-col md="12" style="padding: 0">
              <div style="padding: 0 10px">
                <v-col cols="12" style="padding: 0">
                  <p
                    style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
                  >
                    Tujuan Pemindahan
                  </p>
                </v-col>
                <v-col cols="12" style="padding: 0">
                  <v-textarea dense auto-grow outlined />
                </v-col>
              </div>
            </v-col>
          </v-row>
        </div>
        <hr />
        <div>
          <div
            style="
            display: flex;
            jsutify-content: center;
            align-items: center;
            margin-left: 10px;
          "
          >
            <v-toolbar-title class="overline"
              ><p style="font-size: 16px; margin: 0; font-weight: bold">
                Di Approve Oleh
              </p>
            </v-toolbar-title>
          </div>
          <v-row style="padding: 15px">
            <v-col md="12" style="padding: 0">
              <v-data-table
                :headers="approveHeaders"
                :items="matrix"
                :loading="matrix.length === 0"
                class="elevation-1 mx-auto"
                style="width:99%; margin-bottom:10px; border:1px solid #e0e0e0"
                :items-per-page="10"
                hide-default-footer
              >
              </v-data-table>
              <div style="text-align:right; padding-right:5px;">
                <v-btn
                  v-if="
                    getUserProfile.level.find(
                      ({ application }) => application.id === 1
                    ) !== undefined
                  "
                  color="grey"
                  tile
                  dark
                  small
                  dense
                  style="font-size:10px; width:100px;"
                  @click="fetch"
                >
                  <span>Fetch Matrix</span>
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </v-dialog>
  </div>
</template>
<script>
import { mapMutations, mapGetters } from 'vuex'
export default {
  name: 'Movement-Detail',
  props: ['result', 'permission'],
  data() {
    return {
      dialog: false,
      form: {},
      loading: false,

      headers: [
        { text: 'Nama Aset', value: 'name', align: 'left', sortable: false },
        {
          text: 'Kode Aset',
          value: 'asset_code',
          align: 'left',
          sortable: false
        },
        { text: 'Kode Lot', value: 'lot_code', align: 'left', sortable: false },
        {
          text: 'Kepemilikan',
          value: 'owners',
          align: 'left',
          sortable: false
        },
        {
          text: 'Lokasi saat ini',
          value: 'location',
          align: 'left',
          sortable: false
        },
        {
          text: 'Stock Terakhir',
          value: 'current_stock',
          align: 'left',
          sortable: false
        },
        {
          text: 'Jml. Dipinjamkan',
          value: 'total_loanned',
          align: 'left',
          sortable: false
        },
        {
          text: 'Gudang',
          value: 'warehouse',
          align: 'left',
          sortable: false
        }
      ],

      approveHeaders: [
        { text: 'Sequence', value: 'sequence', align: 'left' },
        { text: 'Doc. No', value: 'document_no', align: 'left' },
        { text: 'Model', value: 'table_name', align: 'center' },
        { text: 'User', value: 'approver_user', align: 'center' },
        { text: 'Min. Approver', value: 'min_approver', align: 'center' },
        { text: 'Approved by', value: 'approved', align: 'center' },
        { text: 'Rejected by', value: 'rejected', align: 'center' }
      ],

      matrix: []
    }
  },
  // created() {
  // },
  computed: {
    ...mapGetters(['getUserProfile'])
  },
  methods: {
    ...mapMutations([]),
    detail() {
      console.log('detail aset')
    },
    clickOutside() {
      this.$emit('close')
    },
    close() {
      this.$emit('close')
      this.dialog = false
    },
    fetch() {}
  }
}
</script>
<style lang="scss" scoped></style>
